import React, {useEffect, useRef, useState} from 'react';
import {Spinner, Table} from 'react-bootstrap';
import moment from 'moment';
import {getFaultData} from 'api/FaultsApi';
import {DATETIME_FORMAT} from 'constants/uiConstants';
import {useAppContext} from 'contexts/AppContext';
import {isEmpty} from 'utils/Utils';

export default function FaultsData({crane, faultObject}) {
    const isMounted= useRef(false);
    const {handleApiError} = useAppContext();
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState({});

    useEffect(() => {
        isMounted.current = true;
        if (!isEmpty(faultObject)) loadFaultData(faultObject);
        return () => isMounted.current = false;
    }, [crane, faultObject]);

    const loadFaultData = fault => {
        setIsLoading(true);
        const onSuccess = response => isMounted.current && setData(response.data);
        const onDone = () => isMounted.current && setIsLoading(false);
        const payload = {motion_uuid: fault.motion.uuid, date: fault.created_date}
        getFaultData(crane.id, payload, onSuccess, handleApiError, onDone);
    };

    let content;
    if (isLoading) content = <tr><td colSpan="3" className="text-center"><Spinner animation="border" /></td></tr>;
    else content = Object.entries(data).map(([dataKey, dataValue]) => {
        if (dataKey === 'timestamp') dataValue.v = moment(dataValue.v).format(DATETIME_FORMAT);
        else if (dataKey === 'current') dataValue.v = dataValue.v.toFixed(3);
        else if (typeof dataValue.v === 'boolean') dataValue.v = dataValue.v.toString();
        return (
            <tr key={dataKey}>
                <td>{dataValue.d}</td>
                <td>{dataValue.c || ''}</td>
                <td>{dataValue.v}</td>
            </tr>
        );
    });

    return (
        <Table striped bordered hover size="sm" id="fault-data-dump">
            <tbody>
                <tr>
                    <td>Fault codes</td>
                    <td>U02-01</td>
                    <td>
                        {Object.entries(faultObject.faults).map(([faultCode, faultText]) => {
                            return <div key={faultCode}>{faultCode}: {faultText}</div>;
                        })}
                    </td>
                </tr>
                {content}
            </tbody>
        </Table>
    );
}
